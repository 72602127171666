import {
  insertAffiliateAdjustBlackOffer,
  getAffiliateAdjustBlacks,
  removeAffiliateAdjustBlack,
} from 'api/affiliate/overall.js';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import { mapActions, mapState } from 'vuex';
export default {
  components: {
    Pagination,
    GroupSelect,
  },
  data() {
    return {
      filterForm: {
        page: 1,
        pageSize: 20,
      },
      total: 0,
      postData: {},
      list: [],
      loading: {
        list: false,
      },
      dialog: {
        visible: false,
        title: '--',
        type: '',
      },
      rules: {
        offerId: [
          { required: true, message: 'offerId不能为空' },
          { type: 'number', message: 'offerId必须为数字值' },
        ],
        affiliateId: [
          { required: true, message: 'affiliateId不能为空' },
          { type: 'number', message: 'affiliateId必须为数字值' },
        ],
      },
    };
  },
  mounted() {
    //获取Affiliate
    this.getAffiliateList();
    this.getList();
  },
  computed: {
    ...mapState('affiliate', {
      affiliatesGroupList: (state) => state.affiliatesGroupList,
      affiliateLoading: (state) => state.affiliateLoading,
    }),
  },
  methods: {
    // get Affiliate
    ...mapActions('affiliate', ['getAffiliateList']),
    // 获取列表
    getList(page) {
      if (page) {
        this.filterForm.page = page;
      }
      this.loading.list = true;
      getAffiliateAdjustBlacks(this.filterForm)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.list = res.result;
            this.total = res.total;
          } else {
            this.$message.error(res.message);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.loading.list = false;
          this.$message.error(e);
        });
    },
    // 删除
    deletes(id) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          removeAffiliateAdjustBlack(id).then((res) => {
            if (res.code == 200) {
              this.$message.success('删除成功！');
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 打开弹窗
    openDialog(type, row) {
      this.dialog.type = type;
      this.dialog.title = type == 'add' ? '添加' : '编辑';
      this.dialog.visible = true;
      if (row) {
        this.postData = Object.assign({}, row);
      }
    },
    // 提交弹窗
    submitDialog(formName) {
      console.log(formName);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading.submitBtn = true;
          if (this.dialog.type === 'add') {
            console.log('添加操作');
            insertAffiliateAdjustBlackOffer(this.postData).then((res) => {
              if (res.code === 200) {
                this.$message.success('添加成功!');
                this.loading.submitBtn = false;
                this.closeDialog(formName);
                this.getList();
              } else {
                this.$message.error(res.message);
              }
            });
          }
          if (this.dialog.type == 'edit') {
            console.log('编辑操作');
          }
        }
      });
    },
    // 关闭弹窗
    closeDialog(formName) {
      this.$refs[formName].resetFields();
      this.dialog.visible = false;
    },
    handleSizeChange(size) {
      this.filterForm.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.filterForm.page = currentPage;
      this.getList();
    },
  },
};
